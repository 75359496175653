import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { GlobalContext } from "../appContext";
import Cookies from "universal-cookie";
import Login from "./Login";
import "../App.css";
import Forgot from "./Forgot";
import ChangePassword from "./ChangePassword";
import Dashboard from "./Dashboard";
import ResetPassword from "./ResetPassword";
import Create from "./Create";

const cookies = new Cookies();

class App extends React.Component {
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          {cookies.get("token") && (
            <Route key="1" path="/dashboard" exact component={Dashboard} />
          )}
          <Route key="2" path="/login" exact component={Login} />
          <Route key="3" path="/forgot" exact component={Forgot} />
          <Route key="4" path="/reset" exact component={ResetPassword} />
          {cookies.get("token") && (
            <Route key="5" path="/changepassword" exact component={ChangePassword} />
          )}
          <Route key="6" path="/create" exact component={Create} />
          {cookies.get("token") ? (
            <Redirect to="/dashboard" />
          ) : urlParams.get("code") ? (
            <Redirect
              to={{ pathname: "/create", state: { code: urlParams.get("code") } }}
            />
          ) : urlParams.get("change") ? (
            <Redirect
              to={{ pathname: "/reset", state: { token: urlParams.get("change") } }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  service: urlParams.get("service") ? urlParams.get("service") : null,
                  urlMsgErr: urlParams.get("message") ? urlParams.get("message") : null,
                },
              }}
            />
          )}
        </Switch>
      </BrowserRouter>
    );
  }
}

App.contextType = GlobalContext;

export default App;
