import React from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../appContext";
import { gql } from "@apollo/client";
import { Button, Form, Input, Spinner } from "reactstrap";
import Panel from "./Layout/Panel";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

class Forgot extends React.Component {
  state = {
    emailSent: false,
    mail: "",
    request: false,
  };

  componentDidMount() {}

  render() {
    if (cookies.get("token")) {
      let url = jwt_decode(cookies.get("token")).url;
      let address = "https://" + url;
      window.location = address;
    }
    return !cookies.get("token") ? (
      <div className="App">
        <Panel
          className="mx-auto mt-4"
          style={{
            width: "360px",
          }}
          rounded
          header={
            <div>
              <h3 className="text-center">
                {this.context.userData.serviceName ||
                  this.props.location.state.serviceName}
              </h3>
              <h4 className="text-center sub-title text-secondary pt-2">
                <strong>FORGOT PASSWORD</strong>{" "}
              </h4>
            </div>
          }
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (this.context.isEmail(this.state.mail)) {
                let _service = this.context.userData.service;
                this.setState({ request: true });
                this.context.client
                  .query({
                    query: gql`
                  query {
                    forgotPassword(mail: "${this.state.mail}", service: "${_service}"){
                      token
                    }
                  }
                `,
                  })
                  .then((result) => {
                    this.setState({
                      request: false,
                      emailSent: true,
                      responseMessage: "Email sent",
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      responseMessage: err.message,
                      err: true,
                      request: false,
                    });
                  });
              } else {
                this.setState({ responseMessage: "Invalid email" }); // change message
              }
            }}
          >
            <div className="mt-1">
              <Input
                type="username"
                onChange={(e) => {
                  this.setState({ mail: e.target.value });
                }}
                onClick={() => {
                  if (this.state.responseMessage) {
                    this.setState({ responseMessage: null });
                  }
                }}
                name="email"
                id="email"
                placeholder="Email"
              />
            </div>
            {this.state.responseMessage ||
            (this.props.location.state && this.props.location.state.urlMsgErr) ? (
              <div
                className={
                  this.state.emailSent
                    ? "py-2 text-success sub-title text-center"
                    : "py-2 text-center sub-title text-danger"
                }
              >
                <strong>
                  {this.state.responseMessage || this.props.location.state.urlMsgErr}
                </strong>
              </div>
            ) : (
              <div className="mt-2 pt-3"></div>
            )}
            <Button
              disabled={this.state.mail.length === 0 || this.state.emailSent}
              type="submit"
              style={{ width: "100%" }}
              color={this.state.request ? "secondary" : "primary"}
              className={this.state.request ? "login-button" : ""}
            >
              {this.state.request ? <Spinner size="sm" color="primary" /> : "Submit"}
            </Button>
          </Form>
        </Panel>
        <Panel
          className="mx-auto mt-1 text-center"
          noBorder
          bgColor
          style={{
            width: "300px",
          }}
        >
          <Link
            to={{
              pathname: "/login",
              state: {
                service: this.context.userData.srv || this.props.location.state.service,
                serviceName:
                  this.context.userData.serviceName ||
                  this.props.location.state.serviceName,
              },
            }}
          >
            {" "}
            Back to login{" "}
          </Link>
        </Panel>
      </div>
    ) : null;
  }
}

Forgot.contextType = GlobalContext;

export default Forgot;
