import React from "react";
import { gql } from "@apollo/client";
import { Button, Form, Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
// import myContext from "../myContext";
import { GlobalContext } from "../appContext";
import Cookies from "universal-cookie";
import Panel from "./Layout/Panel";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

class ChangePassword extends React.Component {
  state = {
    invalid: false,
    request: false,
    password1: "",
    password2: "",
    isPasswordShown: false,
    isPasswordShownB: false,
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  componentDidMount() {}

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="App">
        <div>
          <Panel
            className="mx-auto mt-4"
            style={{
              width: "400px",
            }}
            rounded
            header={
              <div>
                <h3 className="text-center">
                  {this.context.userData.serviceName ||
                    this.props.location.state.userData.serviceName}
                </h3>
                <h5 className="text-center sub-title text-secondary pt-2">
                  <strong>
                    {this.context.userData.acc.toUpperCase() ||
                      this.props.location.state.userData.acc.toUpperCase()}
                  </strong>{" "}
                  - {this.context.userData.eml || this.props.location.state.userData.eml}
                </h5>
              </div>
            }
          >
            <h4 className="text-center sub-title text-secondary pb-2">
              Change current password{" "}
            </h4>

            <Form
              onSubmit={(e) => {
                e.preventDefault();

                if (this.context.checkExp()) {
                  let service = jwt_decode(cookies.get("token")).sub;
                  cookies.remove("token", { path: "/" });
                  this.context.setUserData({});
                  this.props.history.push({
                    pathname: "/login",
                    state: {
                      service: service,
                      urlMsgErr: "Your session has expired.",
                    },
                  });
                } else {
                  let arrayOfWord = this.context.createArray(
                    this.props.location.state.userData
                  );
                  if (this.state.password1 !== this.state.password2) {
                    this.setState({ invalid: true, errMsg: "Passwords do not match" });
                  } else if (this.state.password1.length < 8) {
                    this.setState({ invalid: true, errMsg: "Password too short" });
                  } else if (
                    this.context.check(this.state.password1, arrayOfWord).checked === true
                  ) {
                    this.setState({
                      invalid: true,
                      errMsg: (
                        <div>
                          Password should not contain parts of <br /> URL, mail or service
                          names:
                          <br />
                          <strong>
                            '
                            {
                              this.context.check(this.state.password1, arrayOfWord).msg
                                .chunk
                            }
                            '
                          </strong>{" "}
                          is part of{" "}
                          <strong>
                            '
                            {
                              this.context.check(this.state.password1, arrayOfWord).msg
                                .elem
                            }
                            '
                          </strong>
                        </div>
                      ),
                    });
                  } else {
                    this.setState({ request: true });
                    this.context.client
                      .mutate({
                        mutation: gql`
                      mutation{
                        updatePassword(newPass:"${this.state.password1}"){
                          token
                        }
                      }
                    `,
                        context: {
                          headers: {
                            token: cookies.get("token"),
                          },
                        },
                      })
                      .then((result) => {
                        let url = jwt_decode(result.data.updatePassword.token).url;
                        let address = "https://" + url;
                        cookies.set("token", result.data.updatePassword.token, {
                          path: "/",
                          expires: new Date(Date.now() + 2592000000),
                        });
                        window.location = address;
                      })
                      .catch((err) => {
                        this.setState({ errMsg: err.message, request: false });
                      });
                  }
                }
              }}
            >
              <div className="wrap-input100">
                <Input
                  type={isPasswordShown ? "text" : "password"}
                  onChange={(e) => {
                    this.setState({
                      password1: e.target.value,
                      invalid: false,
                      errMsg: null,
                    });
                  }}
                  name="password"
                  id="password"
                  placeholder="New password"
                  invalid={this.state.invalid}
                  disabled={this.state.request}
                />

                <i
                  className={
                    isPasswordShown
                      ? "fa fa-eye-slash password-icon"
                      : "fa fa-eye password-icon"
                  }
                  onClick={this.togglePasswordVisiblity}
                />
              </div>
              <div className="wrap-input100">
                <Input
                  type={isPasswordShown ? "text" : "password"}
                  onChange={(e) => {
                    this.setState({
                      password2: e.target.value,
                      invalid: false,
                      errMsg: null,
                    });
                  }}
                  name="password"
                  id="password2"
                  placeholder="Confirm new password"
                  invalid={this.state.invalid}
                  disabled={this.state.request}
                />

                <i
                  className={
                    isPasswordShown
                      ? "fa fa-eye-slash password-icon"
                      : "fa fa-eye password-icon"
                  }
                  onClick={this.togglePasswordVisiblity}
                />
              </div>
              {this.state.invalid || this.state.errMsg ? (
                <div
                  className="pb-3 sub-title text-center text-danger"
                  style={{ marginTop: "-10px" }}
                >
                  {this.state.errMsg}
                </div>
              ) : null}
              <div>
                <Button
                  disabled={
                    this.state.password1.length === 0 || this.state.password2.length === 0
                  }
                  style={{ width: "100%" }}
                  type="submit"
                  color={this.state.request ? "secondary" : "primary"}
                  className={this.state.request ? "login-button" : ""}
                >
                  {this.state.request ? <Spinner size="sm" color="primary" /> : "UPDATE"}
                </Button>
              </div>
            </Form>
            <div className="pt-3 sub-title text-secondary">
              Your password must be at least 8 characters
            </div>
          </Panel>
          <Panel
            className="mx-auto text-center"
            noBorder
            bgColor
            style={{
              width: "300px",
            }}
          >
            <Link to={{ pathname: "/dashboard" }}> Back to dashboard </Link>
          </Panel>
        </div>
      </div>
    );
  }
}

ChangePassword.contextType = GlobalContext;

export default ChangePassword;
