import React from "react";
import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Button, Form, Input, Spinner } from "reactstrap";
import Panel from "./Layout/Panel";
import { GlobalContext } from "../appContext";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

class Login extends React.Component {
  state = {
    mail: "",
    password: "",
    serviceName: "",
    errMsg: false,
    invalid: false,
    invalidEmail: false,
    request: false,
    isPasswordShown: false,
  };

  componentDidMount() {
    if (
      (this.props.location.state && this.props.location.state.service) ||
      this.context.userData.srv !== undefined
    ) {
      let _service = this.props.location.state.service || this.context.userData.srv;
      this.context.client
        .query({
          query: gql`
        query {
          getServiceName(service:"${_service}"){
        human_name
          }
        }
      `,
        })
        .then((result) => {
          // document.title = result.data.getServiceName.human_name;
          this.context.setUserData({
            serviceName: result.data.getServiceName.human_name,
            service: this.props.location.state.service,
          });
        })
        .catch((err) => {
          this.setState({ errMsg: "No service found" });
        });
    } else if (
      this.props.location.state &&
      this.props.location.state.service === null &&
      this.context.userData.srv === undefined
    ) {
      this.setState({ errMsg: "No service found" });
    }
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  render() {
    if (cookies.get("token")) {
      let url = jwt_decode(cookies.get("token")).url;
      let address = "https://" + url;
      window.location = address;
    }
    const { isPasswordShown } = this.state;
    return !cookies.get("token") ? (
      <div className="App">
        <Panel
          className="mx-auto mt-4"
          style={{
            width: "360px",
            display: this.context.userData.serviceName ? "none" : null,
          }}
          rounded
        >
          {this.state.errMsg ? (
            <div className="text-center text-danger">{this.state.errMsg}</div>
          ) : (
            <Spinner size="sm" className="loading-spinner" color="primary" />
          )}
        </Panel>

        {this.context.userData.serviceName ? (
          <div>
            <Panel
              className="mx-auto mt-4"
              style={{
                width: "360px",
              }}
              rounded
              header={
                <div>
                  <h3 className="text-center">{this.context.userData.serviceName}</h3>
                  <h4 className="text-center sub-title text-secondary pt-2">
                    <strong>SIGN IN</strong>{" "}
                  </h4>
                </div>
              }
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.context.isEmail(this.state.mail)) {
                    this.setState({ request: true });
                    this.context.client
                      .query({
                        query: gql`
                      query {
                        login(mail: "${this.state.mail}", service:"${this.context.userData.service}", pass: "${this.state.password}"){
                          token
                        }
                      }
                    `,
                      })
                      .then((result) => {
                        cookies.set("token", result.data.login.token, {
                          path: "/",
                          expires: new Date(Date.now() + 2592000000),
                        });
                        let url = jwt_decode(result.data.login.token).url;
                        let address = "https://" + url;
                        window.location = address;
                        // }
                      })
                      .catch((err) => {
                        this.setState({
                          errMsg: err.message,
                          request: false,
                          invalid: true,
                        });
                      });
                  } else {
                    this.setState({
                      errMsg: "Insert valid email address",
                      invalidEmail: true,
                    });
                  }
                }}
              >
                <Input
                  type="username"
                  onChange={(e) => {
                    this.setState({
                      mail: e.target.value,
                      invalid: false,
                      invalidEmail: false,
                      errMsg: null,
                    });
                  }}
                  name="email"
                  id="email"
                  placeholder="Email"
                  invalid={this.state.invalid || this.state.invalidEmail}
                  disabled={this.state.request}
                />
                <div className="mt-1 pt-3 wrap-input100">
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    onChange={(e) => {
                      this.setState({
                        password: e.target.value,
                        invalid: false,
                        errMsg: null,
                      });
                    }}
                    name="password"
                    id="password"
                    placeholder="Password"
                    invalid={this.state.invalid}
                    disabled={this.state.request}
                  />
                  <i
                    className={
                      isPasswordShown
                        ? "fa fa-eye-slash password-icon"
                        : "fa fa-eye password-icon"
                    }
                    onClick={this.togglePasswordVisiblity}
                  />
                </div>
                {this.state.errMsg ||
                (this.props.location.state && this.props.location.state.urlMsgErr) ? (
                  <div
                    className="mb-3 text-center sub-title text-danger"
                    style={{ marginTop: "-10px" }}
                  >
                    {this.state.errMsg ||
                      (this.props.location.state && this.props.location.state.urlMsgErr)}
                  </div>
                ) : null}
                {/* <div
                  className="pb-3 sub-title text-secondary"
                  style={{ marginTop: "-10px", marginLeft: "1.4em" }}
                >
                  <Input
                    onChange={(e) => {
                      this.setState({ rememberMe: e.target.checked });
                    }}
                    type="checkbox"
                  />{" "}
                  <span>Remember me</span>
                </div> */}
                <div>
                  <Button
                    disabled={
                      this.state.mail.length === 0 || this.state.password.length === 0
                    }
                    style={{ width: "100%" }}
                    type="submit"
                    color={this.state.request ? "secondary" : "primary"}
                    className={this.state.request ? "login-button" : ""}
                  >
                    {this.state.request ? <Spinner size="sm" color="primary" /> : "Login"}
                  </Button>
                </div>
              </Form>
            </Panel>
            <Panel
              className="mx-auto text-center"
              noBorder
              bgColor
              style={{
                width: "300px",
              }}
            >
              <Link
                to={{
                  pathname: "/forgot",
                  state: {
                    serviceName: this.context.userData.serviceName,
                    service: this.context.userData.service,
                  },
                }}
              >
                {" "}
                Forgot password{" "}
              </Link>
            </Panel>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

Login.contextType = GlobalContext;

export default Login;
