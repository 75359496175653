import React from "react";
import { gql } from "@apollo/client";
import { GlobalContext } from "../appContext";
import { Button, Form, Input, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Panel from "./Layout/Panel";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

class ResetPassword extends React.Component {
  state = {
    invalid: false,
    errMsg: false,
    request: false,
    password1: "",
    password2: "",
    isPasswordShown: false,
    isPasswordShownB: false,
  };

  componentDidMount() {
    let _now = Math.floor(new Date().getTime() / 1000.0);
    this.context.setToken(this.props.location.state.token);
    let userData = jwt_decode(this.props.location.state.token);
    let service = jwt_decode(this.props.location.state.token).sub;

    if (jwt_decode(this.props.location.state.token).exp < _now) {
      this.context.client
        .query({
          query: gql`
        query {
          getServiceName(service:"${userData.srv}"){
        human_name
          }
        }
      `,
        })
        .then((result) => {
          // document.title = result.data.getServiceName.human_name;
          // this.context.setUserData({
          //   serviceName: result.data.getServiceName.human_name,
          //   service: service,
          // });

          this.props.history.push({
            pathname: "/forgot",
            state: {
              service: service,
              serviceName: result.data.getServiceName.human_name,
              urlMsgErr: "Your token has expired.",
            },
          });
        })
        .catch((err) => {
          this.setState({ err: err });
        });
    } else {
      this.context.client
        .query({
          query: gql`
          query {
            getServiceName(service:"${userData.srv}"){
          human_name
            }
          }
        `,
        })
        .then((result) => {
          // document.title = result.data.getServiceName.human_name;
          userData.serviceName = result.data.getServiceName.human_name;
          this.context.setUserData(userData);
        })
        .catch((err) => {
          this.setState({ err: err });
        });
    }
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="App">
        <Panel
          className="mx-auto mt-4"
          style={{
            width: "360px",
            display: this.context.userData.serviceName || this.state.err ? "none" : null,
          }}
          rounded
        >
          <Spinner size="sm" className="loading-spinner" color="primary" />
        </Panel>

        {this.context.userData.serviceName ? (
          <div>
            <Panel
              className="mx-auto mt-4"
              style={{
                width: "360px",
              }}
              rounded
              header={
                <div>
                  <h3 className="text-center">{this.context.userData.serviceName}</h3>
                  <h5 className="text-center sub-title text-secondary pt-2">
                    <strong>{this.context.userData.acc.toUpperCase()}</strong> -{" "}
                    {this.context.userData.eml}
                  </h5>
                </div>
              }
            >
              <h4 className="text-center sub-title text-secondary">Set new password</h4>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  let arrayOfWord = this.context.createArray(this.context.userData);
                  if (this.state.password1 !== this.state.password2) {
                    this.setState({ invalid: true, errMsg: "Passwords do not match" });
                  } else if (this.state.password1.length < 8) {
                    this.setState({ invalid: true, errMsg: "Password too short" });
                  } else if (
                    this.context.check(this.state.password1, arrayOfWord).checked === true
                  ) {
                    this.setState({
                      invalid: true,
                      errMsg: (
                        <div>
                          Password should not contain parts of <br /> URL, mail or service
                          names:
                          <br />
                          <strong>
                            '
                            {
                              this.context.check(this.state.password1, arrayOfWord).msg
                                .chunk
                            }
                            '
                          </strong>{" "}
                          is part of{" "}
                          <strong>
                            '
                            {
                              this.context.check(this.state.password1, arrayOfWord).msg
                                .elem
                            }
                            '
                          </strong>
                        </div>
                      ),
                    });
                  } else {
                    this.setState({ request: true });
                    this.context.client
                      .mutate({
                        mutation: gql`
                      mutation{
                        updatePassword(newPass:"${this.state.password1}"){
                          token
                        }
                      }
                    `,
                        context: {
                          headers: {
                            token: this.context.token,
                          },
                        },
                      })
                      .then((result) => {
                        let url = jwt_decode(result.data.updatePassword.token).url;
                        let address = "https://" + url;
                        cookies.set("token", result.data.updatePassword.token, {
                          path: "/",
                          expires: new Date(Date.now() + 2592000000),
                        });
                        window.location = address;
                      })
                      .catch((err) => {
                        this.setState({
                          errMsg: err.message,
                          request: false,
                          invalid: true,
                        });
                      });
                  }
                }}
              >
                <div className="wrap-input100">
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    onChange={(e) => {
                      this.setState({
                        password1: e.target.value,
                        invalid: false,
                        errMsg: null,
                      });
                    }}
                    name="password"
                    id="password"
                    placeholder="New password"
                    invalid={this.state.invalid}
                    disabled={this.state.request}
                  />
                  <i
                    className={
                      isPasswordShown
                        ? "fa fa-eye-slash password-icon"
                        : "fa fa-eye password-icon"
                    }
                    onClick={this.togglePasswordVisiblity}
                  />
                </div>
                <div className="wrap-input100">
                  <Input
                    type={isPasswordShown ? "text" : "password"}
                    onChange={(e) => {
                      this.setState({
                        password2: e.target.value,
                        invalid: false,
                        errMsg: null,
                      });
                    }}
                    name="password"
                    id="password2"
                    placeholder="Confirm password"
                    invalid={this.state.invalid}
                    disabled={this.state.request}
                  />
                  <i
                    className={
                      isPasswordShown
                        ? "fa fa-eye-slash password-icon"
                        : "fa fa-eye password-icon"
                    }
                    onClick={this.togglePasswordVisiblity}
                  />
                </div>

                {this.state.invalid || this.state.errMsg ? (
                  <div
                    className="pb-3 sub-title text-center text-danger"
                    style={{ marginTop: "-10px" }}
                  >
                    {this.state.errMsg}
                  </div>
                ) : null}

                <div>
                  <Button
                    disabled={
                      this.state.password1.length === 0 ||
                      this.state.password2.length === 0
                    }
                    style={{ width: "100%" }}
                    type="submit"
                    color={this.state.request ? "secondary" : "primary"}
                    className={this.state.request ? "login-button" : ""}
                  >
                    {this.state.request ? (
                      <Spinner size="sm" color="primary" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
              <div className="pt-3 sub-title text-secondary">
                Your password must be at least 8 characters
              </div>
            </Panel>
            <Panel
              className="mx-auto mt-1 text-center"
              noBorder
              bgColor
              style={{
                width: "300px",
              }}
            >
              <Link
                to={{
                  pathname: "/login",
                  state: {
                    service: this.context.userData.srv,
                    serviceName: this.context.userData.serviceName,
                  },
                }}
              >
                {" "}
                Back to login{" "}
              </Link>
            </Panel>
          </div>
        ) : null}
      </div>
    );
  }
}

ResetPassword.contextType = GlobalContext;

export default ResetPassword;
