import React from "react";
import { gql } from "@apollo/client";
import { Button, Form, Input, Spinner } from "reactstrap";
import { GlobalContext } from "../appContext";
import Cookies from "universal-cookie";
import Panel from "./Layout/Panel";

const cookies = new Cookies();

class Create extends React.Component {
  state = {
    invalid: false,
    errMsg: false,
    isPasswordShown: false,
    request: false,
    password1: "",
    password2: "",
    data: null,
  };

  componentDidMount() {
    this.creationCode = this.props.location.state.code;
    this.context.client
      .mutate({
        mutation: gql`
        mutation{
          createUser(code:"${this.creationCode}"){
            token
            account
            service
            url
            mail
            token
            expire
            human_name
          }
        }
      `,
      })
      .then((result) => {
        // document.title = result.data.createUser.human_name;
        let data = {
          acc: result.data.createUser.account,
          eml: result.data.createUser.mail,
          srv: result.data.createUser.service,
          url: result.data.createUser.url,
          serviceName: result.data.createUser.human_name,
        };
        this.arrayOfWord = this.context.createArray(data);
        this.setState({ data: data });
      })
      .catch((err) => {
        this.setState({ err: err });
      });
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="App">
        <Panel
          className="mx-auto mt-4"
          style={{
            width: "360px",
            display: this.state.data || this.state.err ? "none" : null,
          }}
          rounded
        >
          <Spinner size="sm" className="loading-spinner" color="primary" />
        </Panel>

        {this.state.data ? (
          <Panel
            className="mx-auto mt-4"
            style={{
              width: "360px",
            }}
            rounded
            header={
              <div>
                <h3 className="text-center">{this.state.data.serviceName}</h3>
                <h5 className="text-center sub-title text-secondary pt-2">
                  <strong>{this.state.data.acc.toUpperCase()}</strong> -{" "}
                  {this.state.data.eml}
                </h5>
              </div>
            }
          >
            <h4 className="text-center sub-title text-secondary pb-2">
              Create password{" "}
            </h4>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (this.state.password1 !== this.state.password2) {
                  this.setState({ invalid: true, errMsg: "Passwords do not match" });
                } else if (this.state.password1.length < 8) {
                  this.setState({ invalid: true, errMsg: "Password too short" });
                } else if (
                  this.context.check(this.state.password1, this.arrayOfWord).checked ===
                  true
                ) {
                  this.setState({
                    invalid: true,
                    errMsg: (
                      <div>
                        Password should not contain parts of <br /> URL, mail or service
                        names:
                        <br />
                        <strong>
                          '
                          {
                            this.context.check(this.state.password1, this.arrayOfWord).msg
                              .chunk
                          }
                          '
                        </strong>{" "}
                        is part of{" "}
                        <strong>
                          '
                          {
                            this.context.check(this.state.password1, this.arrayOfWord).msg
                              .elem
                          }
                          '
                        </strong>
                      </div>
                    ),
                  });
                } else {
                  this.setState({ request: true });
                  this.context.client
                    .mutate({
                      mutation: gql`
                  mutation{
                    createUser(code:"${this.creationCode}", pass:"${this.state.password1}"){
                      token
                      url
                    }
                  }
                `,
                    })
                    .then((result) => {
                      cookies.set("token", result.data.createUser.token, {
                        path: "/",
                        expires: new Date(Date.now() + 2592000000),
                      });
                      let address = "https://" + result.data.createUser.url;
                      window.location = address;
                    })
                    .catch((err) => {
                      console.log(err.message.match(/lo/) && err.message.match(/lo/)[0]);

                      let _message;

                      if (
                        err.message.includes("pwned") &&
                        err.message.match(/\d+/) &&
                        err.message.match(/\d+/)[0]
                      ) {
                        _message = (
                          <span>
                            The password cannot be used because it's listed in public
                            disclosure from previous data breaches and has been pwned{" "}
                            <strong>{err.message.match(/\d+/)[0]} </strong>
                            times. This site implements{" "}
                            <a
                              href="https://pages.nist.gov/800-63-3/sp800-63b.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              "NIST Digital Identity Guidelines"
                            </a>
                          </span>
                        );
                      } else {
                        _message = err.message;
                      }

                      this.setState({
                        errMsg: _message,
                        request: false,
                        invalid: true,
                      });
                    });
                }
              }}
            >
              <div className="wrap-input100">
                <Input
                  type={isPasswordShown ? "text" : "password"}
                  onChange={(e) => {
                    this.setState({
                      password1: e.target.value,
                      invalid: false,
                      errMsg: null,
                    });
                  }}
                  name="password"
                  id="password"
                  placeholder="Set password"
                  invalid={this.state.invalid}
                  disabled={this.state.request}
                />
                <i
                  className={
                    isPasswordShown
                      ? "fa fa-eye-slash password-icon"
                      : "fa fa-eye password-icon"
                  }
                  onClick={this.togglePasswordVisiblity}
                />
              </div>
              <div className="wrap-input100">
                <Input
                  type={isPasswordShown ? "text" : "password"}
                  onChange={(e) => {
                    this.setState({
                      password2: e.target.value,
                      invalid: false,
                      errMsg: null,
                    });
                  }}
                  name="password"
                  id="password2"
                  placeholder="Confirm password"
                  invalid={this.state.invalid}
                  disabled={this.state.request}
                />

                <i
                  className={
                    isPasswordShown
                      ? "fa fa-eye-slash password-icon"
                      : "fa fa-eye password-icon"
                  }
                  onClick={this.togglePasswordVisiblity}
                />
              </div>
              {this.state.invalid || this.state.errMsg ? (
                <div
                  className="pb-3 sub-title text-center text-danger"
                  style={{ marginTop: "-10px" }}
                >
                  {this.state.errMsg}
                </div>
              ) : null}

              <div>
                <Button
                  disabled={
                    this.state.password1.length === 0 || this.state.password2.length === 0
                  }
                  style={{ width: "100%" }}
                  type="submit"
                  color={this.state.request ? "secondary" : "primary"}
                  className={this.state.request ? "login-button" : ""}
                >
                  {this.state.request ? <Spinner size="sm" color="primary" /> : "Submit"}
                </Button>
              </div>
            </Form>
            <div className="pt-3 sub-title text-secondary">
              Your password must be at least 8 characters
            </div>
          </Panel>
        ) : null}

        {this.state.err ? (
          <Panel
            className="mx-auto mt-4"
            style={{
              width: "360px",
            }}
            rounded
          >
            <div className="text-center text-danger">{this.state.err.message}</div>
          </Panel>
        ) : null}
      </div>
    );
  }
}

Create.contextType = GlobalContext;

export default Create;
