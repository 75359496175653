import React from "react";
import { gql } from "@apollo/client";
import Cookies from "universal-cookie";
import { Button, Spinner } from "reactstrap";
import { GlobalContext } from "../appContext";
import Panel from "./Layout/Panel";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

class Dashboard extends React.Component {
  state = {};

  componentDidMount() {
    this.context.client
      .query({
        query: gql`
          query {
            updateToken {
              token
              account
              service
              url
              mail
              token
              expire
              human_name
            }
          }
        `,

        context: {
          headers: {
            token: cookies.get("token"),
          },
        },
      })
      .then((result) => {
        cookies.set("token", result.data.updateToken.token, {
          path: "/",
          expires: new Date(Date.now() + 2592000000),
        });
        // document.title = result.data.updateToken.human_name;

        let accountData = {
          srv: result.data.updateToken.service,
          serviceName: result.data.updateToken.human_name,
          acc: result.data.updateToken.account,
          eml: result.data.updateToken.mail,
          url: result.data.updateToken.url,
        };

        this.context.setUserData(accountData);
      })
      .catch((err) => {
        let service = jwt_decode(cookies.get("token")).sub;
        cookies.remove("token", { path: "/" });
        this.context.setUserData({});
        this.props.history.push({
          pathname: "/login",
          state: { service: service, urlMsgErr: err.message },
        });
      });
  }

  render() {
    return (
      <div className="App">
        <Panel
          className="mx-auto mt-4"
          style={{
            width: "360px",
            display: this.context.userData.serviceName || this.state.err ? "none" : null,
          }}
          rounded
        >
          <Spinner size="sm" className="loading-spinner" color="primary" />
        </Panel>

        {this.context.userData.serviceName ? (
          <div>
            <Panel
              className="mx-auto mt-4"
              style={{
                width: "400px",
              }}
              rounded
              header={
                <div>
                  <h3 className="text-center">{this.context.userData.serviceName}</h3>
                  <h5 className="text-center sub-title text-secondary pt-2">
                    <strong>{this.context.userData.acc.toUpperCase()}</strong> -{" "}
                    {this.context.userData.eml}
                  </h5>
                </div>
              }
            >
              <div className="pt-1">
                <Button
                  style={{ width: "100%" }}
                  className="mt-2"
                  color="primary"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/changepassword",
                      state: { userData: this.context.userData },
                    });
                  }}
                >
                  Change password
                </Button>
              </div>
              <div className="pt-3">
                {" "}
                <Button
                  style={{ width: "100%" }}
                  className="mt-2"
                  color="primary"
                  onClick={() => {
                    cookies.remove("token", { path: "/" });
                    let service = this.context.userData.srv;
                    this.context.setUserData({});
                    this.props.history.push({
                      pathname: "/login",
                      state: { service: service },
                    });
                  }}
                >
                  Logout
                </Button>
              </div>
            </Panel>
            <Panel
              className="mx-auto text-center"
              noBorder
              bgColor
              style={{
                width: "300px",
              }}
            >
              <a href={"https://" + this.context.userData.url}>Go to service</a>
            </Panel>
          </div>
        ) : null}
      </div>
    );
  }
}

Dashboard.contextType = GlobalContext;

export default Dashboard;
